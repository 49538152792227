/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, Fragment } from "react";
// import { ThemeProvider } from "@mui/material/styles";

// @mui material components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import { useMealPlanner } from "../../../../hooks/MealPlan";



function AddToMealPlan({ recipe }) {
    const [day, setDay] = useState("");
    const [meal, setMeal] = useState("");
    const [addedToMealPlanSuccess, setAddedToMealPlanSuccess] = useState(false);

    const mealPlanner = useMealPlanner();

    const handleDayChange = (e) => {
        setDay(e.target.value);
    };
    const handleMealChange = (e) => {
        setMeal(e.target.value);
    };

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        try {
            mealPlanner.addMeal(recipe, mealPlanner.Day[day], mealPlanner.Meal[meal]);
        } catch (error) {
            alert(error.message);
        }

        setAddedToMealPlanSuccess(true);

    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAddedToMealPlanSuccess(false);
    };


    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return (
        <Grid container item xs={12} lg={7} sx={{ mx: "auto", mt: "20px" }}>
            <Snackbar
                open={addedToMealPlanSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Added to meal plan"
                action={action}
            />
            <MKBox
                width="100%"
                bgColor="light"
                borderRadius="lg"
                component="form"
                method="post"
                autocomplete="off"
                onSubmit={handleSubmitEvent}
            >
                <Grid
                    container
                    item
                    justifyContent="center"
                    xs={12}
                    my={2}
                >
                    <MKTypography variant="h6" color="Dark" mb={1} mt={2}>
                        Add to Meal Plan
                    </MKTypography>
                </Grid>

                <MKBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-day-label">Day</InputLabel>
                                <Select
                                    labelId="select-day-label"
                                    id="select-day"
                                    value={day}
                                    label="Day"
                                    onChange={handleDayChange}
                                    sx={{ padding: 1.5 }}
                                    IconComponent={() => <ArrowDropDownIcon />}
                                    required
                                >
                                    {Object.keys(mealPlanner.Day).map(
                                        (day, index) => (
                                            <MenuItem value={day}>
                                                {mealPlanner.Day[
                                                    day
                                                ][0].toUpperCase() +
                                                    mealPlanner.Day[day].substring(
                                                        1
                                                    )}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-meal-label">Meal</InputLabel>
                                <Select
                                    labelId="select-meal-label"
                                    id="select-meal"
                                    value={meal}
                                    label="Day"
                                    onChange={handleMealChange}
                                    sx={{ padding: 1.5 }}
                                    IconComponent={() => <ArrowDropDownIcon />}
                                    required
                                >
                                    {Object.keys(mealPlanner.Meal).map(
                                        (meal, index) => (
                                            <MenuItem value={meal} >
                                                {mealPlanner.Meal[meal][0].toUpperCase() + mealPlanner.Meal[meal].substring(1)}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>


                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={2} >
                        <MKButton type="submit" variant="gradient" color="dark" fullWidth >
                            Add
                        </MKButton>
                    </Grid>
                </MKBox>
            </MKBox>
        </Grid>
    );
}

export default AddToMealPlan;
