import React from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FlipCardBack({ foods }) {
  return (
    <MKBox
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        backgroundColor: "#FFFFF5",
      }}
    >
      <MKTypography variant="h5" gutterBottom>
        Foods Rich in Nutrient
      </MKTypography>
      {foods.map((food, index) => (
  <MKBox 
    key={index} 
    display="flex" 
    alignItems="start" 
    justifyContent="flex-start"  
    textAlign="left"  
    mb={1}
    sx={{ width: '100%' }} 
  >
    <img src={food.image} alt={food.name} style={{ height: "100%",width: "75px", marginRight: "8px" }} />
    <MKTypography variant="body2">{food.name}</MKTypography>
  </MKBox>
))}

    </MKBox>
  );
}

FlipCardBack.propTypes = {
  foods: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FlipCardBack;
