/* 
Reference: https://dev.to/miracool/how-to-manage-user-authentication-with-react-js-3ic5
*/

import { useContext, createContext, useState, useEffect } from "react";

const MealPlanContext = createContext();

const MealPlanProvider = ({ children }) => {
    const [mealPlan, setMealPlan] = useState(getMealPlan());

    const Day = {
        SUNDAY: "sunday",
        MONDAY: "monday",
        TUESDAY: "tuesday",
        WEDNESDAY: "wednesday",
        THURSDAY: "thursday",
        FRIDAY: "friday",
        SATURDAY: "saturday"
    }

    const Meal = {
        BREAKFAST: "breakfast",
        LUNCH: "lunch",
        DINNER: "dinner"
    }

    function getMealPlan() {
        return JSON.parse(localStorage.getItem("mealPlan")) || {
            sunday: { breakfast: null, lunch: null, dinner: null },
            monday: { breakfast: null, lunch: null, dinner: null },
            tuesday: { breakfast: null, lunch: null, dinner: null },
            wednesday: { breakfast: null, lunch: null, dinner: null },
            thursday: { breakfast: null, lunch: null, dinner: null },
            friday: { breakfast: null, lunch: null, dinner: null },
            saturday: { breakfast: null, lunch: null, dinner: null }
        }
    };

    const addMeal = (recipe, day, meal) => {
        if (!(day.toUpperCase() in Day) || !(meal.toUpperCase() in Meal)) {
            throw new Error("Invalid day or meal");
        }

        let newMealPlan = getMealPlan();

        newMealPlan = {
            ...newMealPlan, [day]: {
                ...newMealPlan[day],
                [meal]: recipe
            }
        };

        setMealPlan(newMealPlan);

        localStorage.setItem("mealPlan", JSON.stringify(newMealPlan));
    };

    const removeMeal = (day, meal) => {
        // console.log(day, meal);
        let newMealPlan = {
            ...mealPlan, [day]: {
                ...mealPlan[day],
                [meal]: null
            }
        };

        setMealPlan(newMealPlan);

        localStorage.setItem("mealPlan", JSON.stringify(newMealPlan));
    };

    return (
        <MealPlanContext.Provider value={{ addMeal, removeMeal, Meal, Day, mealPlan }}>
            {children}
        </MealPlanContext.Provider>
    );

};

export default MealPlanProvider;

export const useMealPlanner = () => {
    return useContext(MealPlanContext);
};
