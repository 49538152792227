/* 
Reference: https://dev.to/miracool/how-to-manage-user-authentication-with-react-js-3ic5
*/

import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const backendAPI = process.env.REACT_APP_BACKEND_URL;

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("userToken") || "");
    const navigate = useNavigate();
    const loginAction = async (data) => {
        try {
            const response = await fetch(backendAPI + "/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const res = await response.json();
            if (res.success) {
                setUser(res.data.user);
                setToken(res.token);
                localStorage.setItem("userToken", res.token);
                navigate("/presentation");
                return;
            }
            throw new Error(res.message);
        } catch (err) {
            alert("Invalid password");
        }
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("userToken");
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
