import React, { useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// page components
import ExploreNutrientInfo from "pages/DataInsights/components/ExploreNutrientInfo";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard"; 


// Routes
import routes from "router/routes";
import footerRoutes from "router/footer.routes";


// Images
import bgImage from "assets/images/bg-presentation-old.jpg";

function DataInsights() {
  // form data
  const ageRange = ["2-4 year old", "5-8 years old", "9-13 years old", "14-18 years old"];
  const [age, setAge] = React.useState(""); 

  const sexs = ["Female", "Male"];
  const [sex, setSex] = React.useState(""); 

  const [result, setResult] = useState(null);

  // nutrient data
  const nutrientData = {
    "2-4 year old": {
        Female: [
            { nutrient: "Calories", amount: "1000 to 1400" },
            { nutrient: "Protein", amount: "2 to 4 ounces (56 to 112 grams)" },
            { nutrient: "Fruit", amount: "1 to 1 and a half cups" },
            { nutrient: "Vegetables", amount: "1 to 1 and a half cups" },
            { nutrient: "Grain", amount: "3 to 5 ounces (84 to 140 grams)" },
            { nutrient: "Dairy products", amount: "2 to 2 and a half cups" },
        ],
        Male: [
            { nutrient: "Calories", amount: "1000 to 1600" },
            { nutrient: "Protein", amount: "2 to 5 ounces (56 to 140 grams)" },
            { nutrient: "Fruit", amount: "1 to 1 and a half cups" },
            { nutrient: "Vegetables", amount: "1 to 2 cups" },
            { nutrient: "Grain", amount: "3 to 5 ounces (84 to 140 grams)" },
            { nutrient: "Dairy products", amount: "2 to 2 and a half cups" },
        ],
    },
    "5-8 years old": {
      Female: [
            { nutrient: "Calories", amount: "1200 to 1800" },
            { nutrient: "Protein", amount: "3 to 5 ounces (84 to 140 grams)" },
            { nutrient: "Fruit", amount: "1 to 1 and a half cups" },
            { nutrient: "Vegetables", amount: "1 and a half to 2 and a half cups" },
            { nutrient: "Grain", amount: "4 to 6 ounces (112 to 168 grams)" },
            { nutrient: "Dairy products", amount: "2.5 cups" },
        ],
        Male: [
            { nutrient: "Calories", amount: "1200 to 2000" },
            { nutrient: "Protein", amount: "3 to 5.5 ounces (84 to 154 grams)" },
            { nutrient: "Fruit", amount: "1 to 2 cups" },
            { nutrient: "Vegetables", amount: "1 and a half to 2 and a half cups" },
            { nutrient: "Grain", amount: "4 to 6 ounces (112 to 168 grams)" },
            { nutrient: "Dairy products", amount: "2.5 cups" },
        ],
    },
    "9-13 years old": {
      Female: [
            { nutrient: "Calories", amount: "1400 to 2200" },
            { nutrient: "Protein", amount: "4 to 6 ounces (112 to 168 grams)" },
            { nutrient: "Fruit", amount: "1 and a half to 2 cups" },
            { nutrient: "Vegetables", amount: "1 and a half to 3 cups" },
            { nutrient: "Grain", amount: "5 to 7 ounces (140 to 196 grams)" },
            { nutrient: "Dairy products", amount: "3 cups" },
        ],
        Male: [
            { nutrient: "Calories", amount: "1600 to 2600" },
            { nutrient: "Protein", amount: "5 to 6.5 ounces (140 to 182 grams)" },
            { nutrient: "Fruit", amount: "1 and a half to 2 cups" },
            { nutrient: "Vegetables", amount: "2 to 3 and a half cups" },
            { nutrient: "Grain", amount: "5 to 9 ounces (140 to 252 grams)" },
            { nutrient: "Dairy products", amount: "3 cups" },
        ],
    },
    "14-18 years old": {
      Female: [
            { nutrient: "Calories", amount: "1800 to 2400" },
            { nutrient: "Protein", amount: "5 to 6.5 ounces (140 to 182 grams)" },
            { nutrient: "Fruit", amount: "1 and a half to 2 cups" },
            { nutrient: "Vegetables", amount: "2 and a half to 3 cups" },
            { nutrient: "Grain", amount: "6 to 8 ounces (168 to 224 grams)" },
            { nutrient: "Dairy products", amount: "3 cups" },
        ],
        Male: [
            { nutrient: "Calories", amount: "2000 to 3200" },
            { nutrient: "Protein", amount: "5.5 to 7 ounces (154 to 196 grams)" },
            { nutrient: "Fruit", amount: "2 to 2 and a half cups" },
            { nutrient: "Vegetables", amount: "2 and a half to 4 cups" },
            { nutrient: "Grain", amount: "6 to 10 ounces (168 to 280 grams)" },
            { nutrient: "Dairy products", amount: "3 cups" },
        ],
    },
};
  
  // search nutrient based on age and sex
  const handleSearch = () => {
    if (age && sex) {
      setResult(nutrientData[age][sex]);
    } else {
      setResult(null);
    }
  };

  // tips 
  const tips = [
    {
      title: "Make Food Fun",
      description: "Make healthy foods fun, for example by cutting fruit or sandwiches into interesting shapes.",
      icon: "emoji_food_beverage"
    },
    {
      title: "Serve Seasonal Foods",
      description: "Serve a variety of seasonal fruits and vegetables.",
      icon: "local_florist"
    },
    {
      title: "Learn About Food",
      description: "Learn together about how different foods are grown.",
      icon: "eco"
    },
    {
      title: "Involve Your Kids",
      description: "Let your children help with food shopping and preparation and try new foods and recipes together.",
      icon: "shopping_cart"
    },
    {
      title: "Limit Junk Food",
      description: "Limit the amount of junk food you keep in the house.",
      icon: "no_food"
    },
    {
      title: "Healthy Snacks",
      description: "Keep a bowl of fruit handy for snacks.",
      icon: "fruit"
    },
  ];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.-tim.com/product/material-kit-react",
          label: "free ",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              mt={-10}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Nutrient Insights
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Find out how much nutrient your children should intake daily!
            </MKTypography>
          </Grid>
        </Container>
        
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -30,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
       
       <Container>
       <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Select your children's age and sex to see the results
            </MKTypography>
          <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ margin: "50px"}}>
        
            {/* age */}
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Select Your Children's Age</InputLabel>
                <Select
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  label="Select Age Rnage"
                >
                  {ageRange.map((age, index) => (
                    <MenuItem key={index} value={age}>
                      {age}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* sex */}
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Select Your Children's Sex</InputLabel>
                <Select
                  value={sex}
                  onChange={(e) => setSex(e.target.value)}
                  label="Select Sex"
                >
                  {sexs.map((sex, index) => (
                    <MenuItem key={index} value={sex}>
                      {sex}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Search Button */}
          <Grid container spacing={3} justifyContent="center" mt={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MKButton variant="contained" color="success" onClick={handleSearch}>
                Find out
              </MKButton>
            </Grid>
          </Grid>

          {/* Result */}
          <Grid container spacing={3} justifyContent="center" mt={4}>
            {result && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: "bold", backgroundColor: "#A4D287"}}>Nutrient</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold", backgroundColor: "#A4D287"}}>Amount</TableCell>
                      </TableRow>
                      {result.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{item.nutrient}</TableCell>
                          <TableCell align="center">{item.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>

        {/* tips */}
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75}}
        >

<Container sx={{ mt: 6 }}>
          <ExploreNutrientInfo />
        </Container>


        <MKTypography text-align="center" variant="h2" fontWeight="bold" sx={{ margin: "50px"}}>
            Tips on encouraging healthy diets
          </MKTypography>
          <MKTypography variant="body1" color="text">
          Show your child how to eat healthily 
          </MKTypography>
          </Grid>
          <Grid container spacing={3} mt={6} justifyContent="center">
            {tips.map((tip, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <DefaultInfoCard
                  color="info"
                  icon={tip.icon}
                  title={tip.title}
                  description={tip.description}
                />
              </Grid>
            ))}
          </Grid>

        </Container>


      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DataInsights;
