import React, { useState, useEffect } from "react";
import axios from "axios";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete"; 
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";


// Routes
import routes from "router/routes";
import footerRoutes from "router/footer.routes";


// Images
import bgImage from "assets/images/bg-presentation-old.jpg";
import saImage from "assets/images/south-asia.jpg";
import eaImage from "assets/images/east-asia.webp";
import euImage from "assets/images/europe.avif";
import meImage from "assets/images/middle-east.jpeg";

import diwaliImage from "assets/images/diwali.jpg";
import christmasImage from "assets/images/christmas.jpeg";
import cnyImage from "assets/images/chinese-new-year.webp";
import eidImage from "assets/images/eid.jpg";

import BackToHome from "pages/FoodEvents/components/BackToHome";


function FoodEvents() {
  // data
  const regions = [
    {
      name: "South Asia",
      image: saImage,
      events: [
        {
          eventName: "Diwali Festival",
          time: "04:00 PM Sunday, 27th October 2024",
          address: "Dandenong Park 174 Lonsdale, Dandenong Vic Victoria Australia 3175",
          foodsToExpect: [
            "Ladoos made of besan",
            "Tamil Nadu Thali",
            "Mathri",
          ],
          eventImage: diwaliImage,
        },
      ],
    },
    {
      name: "East Asia",
      image: eaImage,
      events: [
        {
          eventName: "Box Hill Lunar New Year Festival",
          time: "From Middle of January, 2025",
          address: "Box Hill Center, Melbourne",
          foodsToExpect: ["Jiaozi", "Fish", "Chunjuan", "Niangao"],
          eventImage: cnyImage,
        },
      ],
    },
    {
        name: "Europe",
        image: euImage,
        events: [
          {
            eventName: "Christmas Square",
            time: "24 November 2024 – 25 December 2024 Daily, 10am-12.30am",
            address: "Swanston St Forecourt",
            foodsToExpect: [
              "Christmas ham",
              "Rich plum pudding",
              "Pavlova topped with fruit",
            ],
            eventImage: christmasImage,
          },
        ],
      },
      {
        name: "Middle East",
        image: meImage,
        events: [
          {
            eventName: "Eid celebrations at Queen Victoria",
            time: "March 30th – Marth 31st ,2025",
            address: "Queen Victoria Market",
            foodsToExpect: [
              "biryani",
              "falafel",
              "kebabs",
              "baklava",
              "coffee",
            ],
            eventImage: eidImage,
          },
        ],
      },
  ];

  const [selectedRegion, setSelectedRegion] = useState(null);

  const handleRegionClick = (regionName) => {
    setSelectedRegion(regionName === selectedRegion ? null : regionName); 
  };

  const getEventsForRegion = (regionName) => {
    const region = regions.find((r) => r.name === regionName);
    return region ? region.events : [];
  };
  
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.-tim.com/product/material-kit-react",
          label: "free ",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              mt={-10}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Local Food Events
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Here list local events where you can find your familiar home food!
            </MKTypography>
          </Grid>
        </Container>
        
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -30,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
       
       <Container>
        
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {/* Region Cards */}
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1 , mb: 2}}>
              {regions.map((region, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card sx={{ cursor: "pointer" }} onClick={() => handleRegionClick(region.name)}>
                    <CardMedia
                      component="img"
                      height="120"
                      image={region.image} 
                      alt={region.name}
                    />
                    <CardContent>
                      <MKTypography variant="h6" textAlign="center" color="dark">
                        {region.name}
                      </MKTypography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            
            {/* Events List */}
            {selectedRegion && (
              <MKBox mt={4}>
                <MKTypography
                  variant="h3"
                  color="text"
                  textAlign="center"
                  mb={2}
                  mt={3}
                >
                  {selectedRegion} Events
                </MKTypography>

                {getEventsForRegion(selectedRegion).map((event, idx) => (
                  <Card key={idx} sx={{ mb: 3, p: 2, boxShadow: "xl" }}>
                    <Grid container spacing={2} alignItems="center">
                      {/* Event Information */}
                      <Grid item xs={12} md={7}>
                        <MKTypography variant="h4" mb={1} color="dark">
                          {event.eventName}
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={1}>
                          <strong>Time: </strong>{event.time}
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={1}>
                          <strong>Address: </strong>{event.address}
                        </MKTypography>
                        <MKTypography variant="body1" color="text">
                          <strong>Foods to Expect: </strong>{event.foodsToExpect.join(", ")}
                        </MKTypography>
                      </Grid>

                      {/* Event Image */}
                      <Grid item xs={12} md={5}>
                        <CardMedia
                          component="img"
                          height="300" 
                          image={event.eventImage} 
                          alt={event.eventName}
                          sx={{ borderRadius: "8px" }} 
                          style={{'max-width': '100%', 'padding-right': '20px'}}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </MKBox>
            )}
           
           
          </Grid>

          


        </Container>
        <Container sx={{ mt: 6 }}>
          <BackToHome />
        </Container>

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default FoodEvents;
