// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo.png";

// const date = new Date().getFullYear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    brand: {
        name: "NutriCulture",
        image: logo,
        route: "/",
    },
    socials: [

    ],
    menus: [
        {
            name: "What do we do?",
            items: [
                { name: "about us", href: "/landing" },
            ],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            .
        </MKTypography>
    ),
};
