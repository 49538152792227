/* 
https://www.emgoto.com/react-table-of-contents/
*/
import MKTypography from "components/MKTypography";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import { useState } from 'react';

const TableOfContents = ({ headings }) => {
    const [openID, setOpenID] = useState("");

    const handleClick = (itemID) => {
        if (openID === itemID) {
            setOpenID("");
        } else {
            setOpenID(itemID);
        }
    };

    return (
        <nav aria-label="Table of contents">
            {/* <Headings headings={headings} /> */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Navigation
                    </ListSubheader>
                }
            >
                {headings.map((heading) => (
                    <>
                        <ListItemButton onClick={() => handleClick(heading)}>
                            <ListItemText primary={heading.title} />
                            {openID === heading ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openID === heading} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {heading.items.map((child) => (
                                    // <MKTypography variant="subtitle2" key={child.id}>
                                    //     <a href={`#${child.id}`}>{child.title}</a>
                                    // </MKTypography>
                                    <ListItemButton  sx={{ pl: 4 }} href={`#${child.id}`} key={child.id} >
                                        <ListItemText primary={child.title} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </>
                    // <MKTypography variant="h6" component="li" key={heading.id}>
                    //     <a href={`#${heading.id}`}>{heading.title}</a>
                    //     {heading.items.length > 0 && (
                    //         <ul>
                    //             {heading.items.map((child) => (
                    //                 <MKTypography variant="subtitle2" key={child.id}>
                    //                     <a href={`#${child.id}`}>{child.title}</a>
                    //                 </MKTypography>
                    //             ))}
                    //         </ul>
                    //     )}
                    // </MKTypography>
                ))}

            </List>
        </nav>
    );
};

export default TableOfContents;