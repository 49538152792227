import React, { useState } from "react";
import axios from "axios";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete"; 
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import ExploreDishConverter from "pages/IngredientConverter/components/ExploreDishConverter";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "router/routes";
import footerRoutes from "router/footer.routes";

// Images
import bgImage from "assets/images/bg-presentation-old.jpg";

function IngredientConverter() {
  // data
  const regions = ["East Asia", "Africa", "Europe"];
  const [region, setRegion] = useState("");
  const [food, setFood] = useState("");
  const [foodOptions, setFoodOptions] = useState([]); 
  const [result, setResult] = useState(null);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  // nutrient list
  const nutrients = [
    "protein_g",
    "fat_g",
    "carbohydrate_g",
    "calcium_mg",
    "iron_mg",
    "zinc_mg",
    "vitamina_ug",
    "vitamind3_ug",
    "vitaminb1_mg",
    "vitaminb6_mg",
    "vitaminb12_ug",
  ];

  const renderNutrientCard = (foodName, foodData) => (
    <Card sx={{ minWidth: 200, mb: 5, ml: 15, mr: 15, borderRadius: 3, boxShadow: 3, backgroundColor: '#DCF1D1' }}>
      <CardContent>
        <Typography variant="body1" component="div" gutterBottom sx={{ fontSize: "1rem" }}>
          {foodName} (per 100 g)
        </Typography>
        <Divider sx={{ my: 2 }} />
        {nutrients.map((key) => (
          <Box key={key} display="flex" justifyContent="space-between" py={1}>
            <Typography color="textSecondary" sx={{ fontSize: "0.8rem" }}>
              {key.replace(/_/g, ' ').toUpperCase()}
            </Typography>
            <Typography color="textPrimary" sx={{ fontSize: "0.8rem" }}>
              {foodData[key] || "N/A"}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );

  // Function to handle food name input change for autocomplete
  const handleFoodInputChange = (event, value) => {
    setFood(value);
    if (value) {
      axios.get(`${baseUrl}/autocomplete?query=${value}&region=${region}`).then((response) => {
        setFoodOptions(response.data);
      });
    } else {
      setFoodOptions([]);
    }
  };

  // handle food search
  const handleSearch = () => {
    axios.get(`${baseUrl}/search?foodname=${food}&region=${region}`)
      .then((response) => {
        if (response.data && response.data.eaFood) {
          setResult(response.data);
        } else {
          setResult({ eaFood: {}, similarFoods: [] });
        }
      })
      .catch(() => {
        setResult("Sorry, this food is not found");
      });
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.-tim.com/product/material-kit-react",
          label: "free ",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              mt={-10}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Ingredient Converter
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Match your home food with local food here!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -30,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center" alignItems="center">

            {/* region */}
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Select Region</InputLabel>
                <Select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  label="Select Region"
                >
                  {regions.map((region, index) => (
                    <MenuItem key={index} value={region}>
                      {region}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* select home food with autocomplete */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                freeSolo
                options={foodOptions}
                value={food}
                onInputChange={handleFoodInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Enter Home Food"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* search button */}
          <Grid container spacing={3} justifyContent="center" mt={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <MKButton variant="contained" color="success" onClick={handleSearch}>
                Search
              </MKButton>
            </Grid>
          </Grid>

          {/* result */}
          <Grid container spacing={3} justifyContent="center" mt={4}>
            {result && (
              <>
                {/* Card for Searched Food */}
                <Grid item xs={12} md={6}>
                  <Typography textAlign="center" variant="h5" color="dark" mb={2}>
                    Searched Food Nutrition Breakdown
                  </Typography>
                  <Box sx={{ position: 'sticky', top: '100px' }}>
                    {result.eaFood && renderNutrientCard(result.eaFood.foodname, result.eaFood)}
                  </Box>
                </Grid>

                {/* Cards for Similar Foods */}
                <Grid item xs={12} md={6}>
                  <Typography textAlign="center" variant="h5" color="dark" mb={2}>
                    Top 10 Similar Foods Nutrition Breakdown
                  </Typography>
                  {result.similarFoods && result.similarFoods.length > 0 ? (
                    result.similarFoods.map((food, index) => (
                      <Box key={index} mb={3}>
                        {renderNutrientCard(food.foodname, food)}
                      </Box>
                    ))
                  ) : (
                    <Typography textAlign="center" variant="body1" color="textSecondary">
                      No similar foods found in Australia. Please try something else.
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Container>

        <Container sx={{ mt: 6 }}>
          <ExploreDishConverter />
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default IngredientConverter;
