/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Private routers and authentication
import PrivateRoute from "router/PrivateRoute";
import AuthProvider from "hooks/AuthProvider";

// Meal Plan
import MealPlanProvider from "hooks/MealPlan";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import PageNotFound from "layouts/pages/landing-pages/page-not-found";
import Login from "layouts/pages/authentication/login";
// import Logout from "layouts/pages/authentication/logout";
import Landing from "layouts/pages/landing-pages/landing";

// Material Kit 2 React routes
import routes from "router/routes";

export default function App() {
    const { pathname } = useLocation();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return (
                    <Route element={<PrivateRoute />}>
                        <Route exact path={route.route} element={route.component} key={route.key} />
                    </Route>
                );
            }

            return null;
        });

    return (
        <AuthProvider>

            <MealPlanProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        {/* PRIVATE ROUTES */}
                        <Route element={<PrivateRoute />}>
                            <Route path="/presentation" element={<Presentation />} />
                        </Route>
                        {getRoutes(routes)}

                        {/* PUBLIC ROUTES */}
                        <Route path="/landing" element={<Landing />} />
                        <Route path="/login" element={<Login />} />

                        {/* ROUTE NOT FOUND */}
                        <Route path="*" element={<Navigate to="/presentation" />} />
                    </Routes>
                </ThemeProvider>
            </MealPlanProvider>

        </AuthProvider>

    );
}
