/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";




import { useMealPlanner } from "../../hooks/MealPlan";
import RecipeCard from "./components/RecipeCard";

import TableOfContents from "components/TableOfContents";



function NavBlock() {
    const mealPlanner = useMealPlanner();
    const headings = getHeadings();

    function getHeadings() {
        let headings = [];

        for (const day of Object.keys(mealPlanner.mealPlan)) {
            let headingDay = {
                id: day + "-breakfast-header",
                title: day[0].toUpperCase() + day.substring(1),
                items: []
            };

            for (const meal of Object.keys(mealPlanner.mealPlan[day])) {
                headingDay.items.push({
                    id: day + "-" + meal + "-header",
                    title: meal[0].toUpperCase() + meal.substring(1),
                });
            }

            headings.push(headingDay);
        }

        return headings;
    }

    return (
        <MKBox component="section" my={6} py={6}>
            {/* <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    flexDirection="column"
                    alignItems="center"
                    sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
                >
                    <MKBadge
                        variant="contained"
                        color="info"
                        badgeContent="Infinite combinations"
                        container
                        sx={{ mb: 2 }}
                    />
                    <MKTypography variant="h2" fontWeight="bold">
                        Huge collection of sections
                    </MKTypography>
                    <MKTypography variant="body1" color="text">
                        We have created multiple options for you to put together and customise into pixel
                        perfect pages.
                    </MKTypography>
                </Grid>
            </Container> */}
            <Container sx={{ mt: 6 }}>
                <Grid container spacing={3} sx={{ mb: 10 }} key="nav-toc">
                    <Grid item xs={12} lg={3}>
                        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                            <TableOfContents headings={headings} />
                            {/* <MKTypography variant="h3" fontWeight="bold" mb={1}>
                                nav-toc
                            </MKTypography>
                            <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                                nav-toc
                            </MKTypography> */}
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={3}>
                            {mealPlanner.mealPlan && (
                                Object.keys(mealPlanner.mealPlan).map((day, index) => (
                                    Object.keys(mealPlanner.mealPlan[day]).map((meal, index) => (
                                        mealPlanner.mealPlan[day][meal] ? (
                                            <Grid item xs={12} md={12} sx={{ mb: 2 }} key={day + "-" + meal}>
                                                <MKTypography variant="h3" fontWeight="bold" mb={1} id={day + "-" + meal + "-header"}>
                                                    {day[0].toUpperCase() + day.substring(1)}
                                                </MKTypography>
                                                <MKTypography variant="subtitle1" fontWeight="regular" color="secondary" mb={1} pr={2}>
                                                    {meal[0].toUpperCase() + meal.substring(1)}
                                                </MKTypography>
                                                {/* <MKBox component="section"> */}
                                                <RecipeCard recipe={mealPlanner.mealPlan[day][meal]} day={day} meal={meal} />
                                                {/* </MKBox> */}
                                                <br />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} md={12} sx={{ mb: 2 }} key={day + "-" + meal}>
                                                <MKTypography variant="h3" fontWeight="bold" mb={1} id={day + "-" + meal  + "-header"}>
                                                    {day[0].toUpperCase() + day.substring(1)}
                                                </MKTypography>
                                                <MKTypography variant="subtitle1" fontWeight="regular" color="secondary" mb={1} pr={2}>
                                                    {meal[0].toUpperCase() + meal.substring(1)}
                                                </MKTypography>
                                                No recipe selected
                                            </Grid>


                                        )
                                    ))
                                ))
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default NavBlock;
