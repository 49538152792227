import axios from "axios";
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// page sections
// import RecipeCard from "./components/RecipeCard";
import RecipeCard from "./components/RecipeCard";

// Routes
import routes from "router/routes";
import footerRoutes from "router/footer.routes";

// Images
import bgImage from "assets/images/bg-landing.jpg";

import ExploreMealPlanner from "pages/WholeDishConverter/components/ExploreMealPlanner";


function WholeDishConverter() {
    const backendAPI = process.env.REACT_APP_BACKEND_URL;
    const [dish, setDish] = useState("");
    const [similarRecipes, setSimilarRecipes] = useState(null);
    const [success, setSuccess] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        setLoading(true);

        axios.get(`${backendAPI}/similarRecipe/${dish}`)
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    setSimilarRecipes(response.data);
                    setSuccess(true);
                    setLoading(false);
                } else {
                    // setSimilarRecipes({ eaFood: {}, similarFoods: [] });
                    throw new Error("No data found");
                }

            })
            .catch(() => {
                console.log("error retrieving data");
                setSuccess(false);
                setLoading(false);
            });

        // let recipes = [{
        //     "name": "Vegetarian Curry with Chickpeas and Sweet Potato",
        //     "description": "A flavorful and satisfying curry with chickpeas, sweet potato, and a creamy coconut milk base.",
        //     "ingredients": [
        //         "1 can (400g) chickpeas, rinsed and drained",
        //         "1 sweet potato, peeled and diced",
        //         "1 tbsp vegetable oil",
        //         "1 onion, chopped",
        //         "2 cloves garlic, minced",
        //         "1 inch ginger, grated",
        //         "1 red bell pepper, diced",
        //         "1 can (400ml) coconut milk",
        //         "1/4 cup curry powder",
        //         "1 tbsp tomato paste",
        //         "1 tsp turmeric powder",
        //         "Salt and pepper to taste",
        //         "Chopped cilantro for garnish (optional)"
        //     ],
        //     "instructions": [
        //         "Heat oil in a large pot over medium heat.",
        //         "Add onion, garlic, and ginger and cook until softened.",
        //         "Stir in red bell pepper and sweet potato.",
        //         "Add chickpeas, coconut milk, curry powder, tomato paste, and turmeric.",
        //         "Bring to a simmer and cook for 15-20 minutes, or until sweet potato is tender.",
        //         "Season with salt and pepper to taste.",
        //         "Serve hot with rice or naan bread, garnished with cilantro."
        //     ],
        //     "nutritional_values": {
        //         "calories": "350-450",
        //         "protein": "20-25g",
        //         "carbs": "40-50g",
        //         "fat": "10-15g"
        //     },
        //     "notes": "This curry is a great source of fiber and plant-based protein."
        // }, {
        //     "name": "Vegetarian Curry with Chickpeas and Sweet Potato",
        //     "description": "A flavorful and satisfying curry with chickpeas, sweet potato, and a creamy coconut milk base.",
        //     "ingredients": [
        //         "1 can (400g) chickpeas, rinsed and drained",
        //         "1 sweet potato, peeled and diced",
        //         "1 tbsp vegetable oil",
        //         "1 onion, chopped",
        //         "2 cloves garlic, minced",
        //         "1 inch ginger, grated",
        //         "1 red bell pepper, diced",
        //         "1 can (400ml) coconut milk",
        //         "1/4 cup curry powder",
        //         "1 tbsp tomato paste",
        //         "1 tsp turmeric powder",
        //         "Salt and pepper to taste",
        //         "Chopped cilantro for garnish (optional)"
        //     ],
        //     "instructions": [
        //         "Heat oil in a large pot over medium heat.",
        //         "Add onion, garlic, and ginger and cook until softened.",
        //         "Stir in red bell pepper and sweet potato.",
        //         "Add chickpeas, coconut milk, curry powder, tomato paste, and turmeric.",
        //         "Bring to a simmer and cook for 15-20 minutes, or until sweet potato is tender.",
        //         "Season with salt and pepper to taste.",
        //         "Serve hot with rice or naan bread, garnished with cilantro."
        //     ],
        //     "nutritional_values": {
        //         "calories": "350-450",
        //         "protein": "20-25g",
        //         "carbs": "40-50g",
        //         "fat": "10-15g"
        //     },
        //     "notes": "This curry is a great source of fiber and plant-based protein."
        // }]

        // setSimilarRecipes(recipes);

    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setDish(value);
    };




    return (
        <>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <MKBox
                minHeight="50vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>

                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >

                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Whole Dish Converter
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Enter a dish and get recipes with similar nutritional content!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>


            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >



                {/* Dish Input Form */}
                <MKBox component="section" py={12}>
                    <Container>
                        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
                            <MKTypography variant="h3" mb={1}>
                                Enter a dish:
                            </MKTypography>
                        </Grid>
                        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                            <MKBox width="100%" component="form" method="get" autoComplete="off" onSubmit={handleSubmitEvent}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {success ? (
                                            <MKInput variant="standard" label="Dish Name" onChange={handleInput} fullWidth />
                                        ) : (
                                            <MKInput variant="standard" label="Dish Name" onChange={handleInput} fullWidth error helperText="Unable to match recipe" />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container item justifyContent="center" xs={12} my={2}>
                                    <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                                        Find Recipes
                                    </MKButton>
                                </Grid>
                            </MKBox>
                        </Grid>
                    </Container>
                </MKBox>

                {similarRecipes && (
                    <>
                        {similarRecipes.length > 0 ? (
                            similarRecipes.map((recipe, index) => (
                                <MKBox component="section" py={12}>
                                    <RecipeCard recipe={recipe} />

                                </MKBox>
                            ))
                        ) : (
                            <MKTypography textAlign="center" variant="body1" color="textSecondary">
                                No recipes found.
                            </MKTypography>
                        )}
                        <Container sx={{ mt: 6 }}>
                            <ExploreMealPlanner />
                        </Container>
                    </>
                )}


            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default WholeDishConverter;
