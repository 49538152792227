/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images

function NewsStudies() {
    return (
        <MKBox component="section" py={2}>
            <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
                <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
                    <MKTypography variant="h2" color="inherit" mb={1}>
                        Problem
                    </MKTypography>
                    <MKTypography variant="body1" color="inherit" mb={6}>
                    For migrant families living in Victoria, Australia, particularly those with children, there are significant challenges in accessing timely, reliable, and comprehensive health and well-being information. These families often face difficulties in finding familiar foods from their home countries, which are essential for maintaining their nutritional balance. Due to cultural and language barriers and unfamiliarity with the local food and health systems, children from immigrant families may struggle with adapting to new dietary options, potentially leading to nutritional deficiencies and health inequities. How can we help them balance their nutrition intake?
                    </MKTypography>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default NewsStudies;
