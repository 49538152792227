import React, { useState } from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import Card from "@mui/material/Card";

function FlipCard({ frontContent, backContent }) {
  const [flipped, setFlipped] = useState(false);

  const handleMouseEnter = () => setFlipped(true);
  const handleMouseLeave = () => setFlipped(false);

  return (
    <MKBox sx={{ perspective: "1000px", height: "300px", width: "250px" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          transformStyle: "preserve-3d",
          transform: flipped ? "rotateY(180deg)" : "rotateY(0)",
          transition: "transform 0.8s ease",
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            zIndex: 2,
          }}
        >
          {frontContent}
        </MKBox>
        <MKBox
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          {backContent}
        </MKBox>
      </Card>
    </MKBox>
  );
}

FlipCard.propTypes = {
  frontContent: PropTypes.node.isRequired,
  backContent: PropTypes.node.isRequired,
};

export default FlipCard;
