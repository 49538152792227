/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Landing page sections
import NewsStudies from "./sections/NewsStudies";
import Problem from "./sections/Problem";

// Routes
import routes from "router/noAuth.routes";
import footerRoutes from "router/footer.routes";
import { useNavigate } from "react-router-dom";

// Images
import bgImage from "assets/images/bg-landing.jpg";

function Landing() {
    const navigate = useNavigate();

    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            What are we eating today?
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Worried about feeding your children the right food? <br />
                            We are here to help your families get the right amount of nutrition needed.
                        </MKTypography>
                        {/* <MKButton variant="contained" color="white" sx={{ color: ({ palette: { dark } }) => dark.main }} 
                        onClick={() => navigate("/pages/presentation")}>
                            Login
                        </MKButton> */}

                        <MKButton variant="contained" color="white" sx={{ color: ({ palette: { dark } }) => dark.main }} 
                        onClick={() => navigate("/login")}>
                &nbsp;Login
              </MKButton>
                

                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find out more
                            <br />
                            <FontAwesomeIcon icon={faChevronDown} />
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-facebook" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-instagram" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-twitter" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#">
                                <i className="fab fa-google-plus" />
                            </MKTypography>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Problem />
                <NewsStudies />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Landing;
