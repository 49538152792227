import React from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FlipCardFront({ image, title, description }) {
  return (
    <MKBox
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        backgroundColor: "#E7F1E0", 
        borderRadius: "10px",
      }}
    >
      {/* Circular Image */}
      <MKBox
        component="img"
        src={image}
        alt={title}
        sx={{
          width: "100px",
          height: "100px",
          borderRadius: "50%", 
          objectFit: "cover",
          mb: 2, 
        }}
      />
      
      <MKTypography variant="h4" color="dark" gutterBottom>
        {title}
      </MKTypography>
      <MKTypography variant="body2" color="text" textAlign="center">
        {description}
      </MKTypography>
    </MKBox>
  );
}

FlipCardFront.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FlipCardFront;
