
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import AddToMealPlan from "./addToMealPlan";

function RecipeCard({ recipe }) {

    return (
        <Card sx={{ mt: 3 }}>
            <MKBox pt={2.5} pb={2.5} pr={4} pl={4} lineHeight={1}>
                <MKTypography variant="h5">{recipe.name}</MKTypography>
                <MKTypography variant="body2" color="text">
                    {recipe.description}
                </MKTypography>

                <MKTypography variant="h6" color="dark" mb={1} mt={2}>
                    Ingredients
                </MKTypography>
                {recipe.ingredients && recipe.ingredients.length > 0 && (
                    <ul>
                        {recipe.ingredients.map((ingredient, index) => (
                            <MKTypography variant="body2" color="text" pl={5}>
                                <li>{ingredient}</li>
                            </MKTypography>
                        ))}
                    </ul>
                )}

                <MKTypography variant="h6" color="dark" mb={1} mt={2}>
                    Instructions
                </MKTypography>
                {recipe.instructions && recipe.instructions.length > 0 && (
                    <ol>
                        {recipe.instructions.map((instruction, index) => (
                            <MKTypography variant="body2" color="text" pl={5}>
                                <li>{instruction}</li>
                            </MKTypography>
                        ))}
                    </ol>
                )}
                <MKBox align="center" mt="10px" mb="10px">
                    <TableContainer sx={{ maxWidth: 500 }} component={Paper}>
                        <Table size="small">
                            <TableHead sx={{ display: "table-header-group" }}>
                                <TableRow>
                                    <TableCell>Nutrition</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key="calories"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        Calories
                                    </TableCell>
                                    <TableCell align="right">
                                        {recipe.nutritional_values.calories}
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    key="protein"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        Protein
                                    </TableCell>
                                    <TableCell align="right">
                                        {recipe.nutritional_values.protein}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key="carbs"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        Carbohydrates
                                    </TableCell>
                                    <TableCell align="right">
                                        {recipe.nutritional_values.carbs}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key="fat"
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        Fat
                                    </TableCell>
                                    <TableCell align="right">
                                        {recipe.nutritional_values.fat}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MKBox>
                <MKTypography variant="h6" color="dark" mb={1} mt={2}>
                    Notes
                </MKTypography>
                <MKTypography variant="body2" color="text">
                    {recipe.notes}
                </MKTypography>

                <AddToMealPlan recipe={recipe} />

            </MKBox>
        </Card>
    );
}

export default RecipeCard;
