/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import ingredientConverterPage from "assets/images/igt-cvt-page.png";
import NutrientInfoPage from "assets/images/Nutrient-info-page.png";
import MealConverterPage from "assets/images/wh-cvt-page.png";
import MealPlannerPage from "assets/images/meal-planner.png";
import comingSoon from "assets/images/coming-soon.webp";

export default [
  {
    image: ingredientConverterPage,
    name: "Ingredient Converter",
    route: "/pages/ingredient-converter",
  },
  {
    image: MealConverterPage,
    name: "Whole Dish Converter",
    route: "/pages/whole-dish-converter",
  },
  {
    image: NutrientInfoPage,
    name: "Nutrient Information",
    route: "/pages/nutrient-info",
  },
  {
    image: MealPlannerPage,
    name: "Meal planner",
    route: "/pages/meal-planner",
  },
];
