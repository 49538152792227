import React, { useState, useEffect } from "react";
import axios from "axios";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import FlipCard from "examples/Cards/FlipCard";
import FlipCardFront from "examples/Cards/FlipCard/FlipCardFront";
import FlipCardBack from "examples/Cards/FlipCard/FlipCardBack";

// Routes
import routes from "router/routes";
import footerRoutes from "router/footer.routes";

// Images
import bgImage from "assets/images/bg-presentation-old.jpg";
import proteinImage from "assets/images/nutrients/protein.jpeg";
import fatImage from "assets/images/nutrients/fat.jpeg";
import carbImage from "assets/images/nutrients/carb.jpeg";
import caImage from "assets/images/nutrients/ca.webp";
import ironImage from "assets/images/nutrients/iron.jpg";
import zincImage from "assets/images/nutrients/zinc.jpeg";
import vaImage from "assets/images/nutrients/va.jpeg";
import vdImage from "assets/images/nutrients/vd.jpeg";

import kgrmeatImage from "assets/images/nutrients/foods/kgr-meat.jpg";
import milkImage from "assets/images/nutrients/foods/milk.jpg";
import quinoaImage from "assets/images/nutrients/foods/quinoa.png";
import salmonImage from "assets/images/nutrients/foods/salmon.jpg";
import macaImage from "assets/images/nutrients/foods/macadamias.jpg";
import wagyuImage from "assets/images/nutrients/foods/aus-wagyu.jpg";
import cheddarImage from "assets/images/nutrients/foods/cheddar.jpg";
import avoImage from "assets/images/nutrients/foods/avocado.jpg";
import riceImage from "assets/images/nutrients/foods/rice.jpg";
import pastaImage from "assets/images/nutrients/foods/pasta.jpg";
import potatoImage from "assets/images/nutrients/foods/potatoes.jpg";
import mpieImage from "assets/images/nutrients/foods/meat-pie.jpg";
import kaleImage from "assets/images/nutrients/foods/kale.jpg";
import tofuImage from "assets/images/nutrients/foods/Tofu.jpg";
import sdImage from "assets/images/nutrients/foods/sadines.jpg";
import beefImage from "assets/images/nutrients/foods/beef.jpg";
import musselImage from "assets/images/nutrients/foods/mussels.jpg";
import apcImage from "assets/images/nutrients/foods/apricots.jpg";
import dchocImage from "assets/images/nutrients/foods/d-choc.jpg";
import lambImage from "assets/images/nutrients/foods/lamb.jpg";
import chicImage from "assets/images/nutrients/foods/chicken.jpg";
import oysterImage from "assets/images/nutrients/foods/oyster.jpg";
import cashewImage from "assets/images/nutrients/foods/cashews.jpg";
import mckrImage from "assets/images/nutrients/foods/mackerel.jpg";
import mangoImage from "assets/images/nutrients/foods/mango.jpg";
import pepperImage from "assets/images/nutrients/foods/pepper.jpg";
import silbeetImage from "assets/images/nutrients/foods/silverbeet.jpg";
import tunaImage from "assets/images/nutrients/foods/tuna.jpg";
import eggImage from "assets/images/nutrients/foods/egg.jpg";
import swfishImage from "assets/images/nutrients/foods/swordfish.jpg";

// Presentation page components
import ExploreIngredientConverter from "pages/NutrientInfo/components/ExploreIngredientConverter";

function NutrientInfo() {
  // State to manage vegetarian filter
  const [vegetarian, setVegetarian] = useState(false);

  // Nutrients and their foods
  const nutrients = [
    {
      name: "Protein",
      image: proteinImage,
      description: "Essential for children's growth and development.",
      foods: [
        { name: "Kangaroo Meat", image: kgrmeatImage, vegetarian: false },
        { name: "Milk", image: milkImage, vegetarian: true },
        { name: "Australian Quinoa", image: quinoaImage, vegetarian: true },
        { name: "Tofu", image: tofuImage, vegetarian: true }
      ],
    },
    {
      name: "Fat",
      image: fatImage,
      description: "Crucial for brain development in children.",
      foods: [
        { name: "Macadamias", image: macaImage, vegetarian: true},
        { name: "Australian Wagyu", image: wagyuImage, vegetarian: false },
        { name: "Cheddar", image: cheddarImage, vegetarian: true },
        { name: "Avocados", image: avoImage, vegetarian: true},
      ],
    },
    {
      name: "Carbohydrates",
      image: carbImage,
      description: "Provide the primary energy source for children.",
      foods: [
        { name: "Rice", image: riceImage, vegetarian: true },
        { name: "Pasta", image: pastaImage, vegetarian: true  },
        { name: "Potatoes", image: potatoImage, vegetarian: true  },
        { name: "Aussie Meat Pies", image: mpieImage, vegetarian: false  },
      ],
    },
    {
      name: "Calcium",
      image: caImage,
      description: "Essential for building strong bones and teeth.",
      foods: [
        { name: "Kale", image: kaleImage, vegetarian: true },
        { name: "Tofu", image: tofuImage, vegetarian: true },
        { name: "Milk", image: milkImage, vegetarian: true },
        { name: "Canned Sardines", image: sdImage, vegetarian: false },
      ],
    },
    {
      name: "Iron",
      image: ironImage,
      description: "Helps carry oxygen in the blood.",
      foods: [
        { name: "Beef", image: beefImage, vegetarian: false },
        { name: "Mussels", image: musselImage, vegetarian: false },
        { name: "Dried Apricots", image: apcImage, vegetarian: true },
        { name: "Dark Chocolate", image: dchocImage, vegetarian: true },
      ],
    },
    {
      name: "Zinc",
      image: zincImage,
      description: "Supports immune function in children.",
      foods: [
        { name: "Lamb", image: lambImage, vegetarian: false },
        { name: "Chicken", image: chicImage, vegetarian: false },
        { name: "Oysters", image: oysterImage, vegetarian: false },
        { name: "Cashews", image: cashewImage, vegetarian: true },
      ],
    },
    {
      name: "Vitamin A",
      image: vaImage,
      description: "Essential for eye health and vision.",
      foods: [
        { name: "Mackerel", image: mckrImage, vegetarian: false },
        { name: "Mango", image: mangoImage, vegetarian: true },
        { name: "Red Bell Peppers", image: pepperImage, vegetarian: true },
        { name: "Silverbeet", image: silbeetImage, vegetarian: true },
      ],
    },
    {
      name: "Vitamin D",
      image: vdImage,
      description: "Important for bone health.",
      foods: [
        { name: "Tuna", image: tunaImage, vegetarian: false },
        { name: "Egg", image: eggImage, vegetarian: true },
        { name: "Oysters", image: oysterImage, vegetarian: false },
        { name: "Swordfish", image: swfishImage, vegetarian: false },
      ],
    },
  ];

  // Function to filter foods based on vegetarian option
  const filterFoods = (foods) => {
    if (vegetarian) {
      return foods.filter(food => food.vegetarian);
    }
    return foods;
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.-tim.com/product/material-kit-react",
          label: "free ",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              mt={-10}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Nutrient Info
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Learn about some important nutrients for your children!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -30,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {/* Vegetarian Toggle */}
            <Grid item xs={12} textAlign="center">
              <FormControlLabel
                control={
                  <Switch checked={vegetarian} onChange={() => setVegetarian(!vegetarian)} />
                }
                label="Vegetarian"
              />
            </Grid>

            {nutrients.map((nutrient, index) => (
              <Grid item key={index}>
                <FlipCard
                  frontContent={
                    <FlipCardFront
                      image={nutrient.image}
                      title={nutrient.name}
                      description={nutrient.description}
                    />
                  }
                  backContent={<FlipCardBack foods={filterFoods(nutrient.foods)} />}
                />
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container sx={{ mt: 6 }}>
          <ExploreIngredientConverter />
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default NutrientInfo;
