/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import post3 from "assets/images/examples/blog-9-4.jpg";
import post4 from "assets/images/examples/blog2.jpg";

function News() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6} id="news-articles">
          <MKTypography variant="h3" mb={6}>
            Dive deeper into the issue
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Migration, Stress and the Challenges of Accessing Food: An Exploratory Study of the Experience of Recent Afghan Women Refugees in Adelaide, Australia "
              description="This study explored the migration and food experiences of Afghani women refugees residing in Adelaide, South Australia..."
              action={{
                type: "internal",
                route: "https://doi.org/10.3390/ijerph17041379",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Drivers of Overweight/Obesity in 4–11 Year Old Children of Australians and Immigrants; Evidence from Growing Up in Australia"
              description="Despite high overweight/obesity rates in children of Australian immigrants, the risk factors are unknown. We investigated behavioural risk factors and their association with overweight/obesity in 4—11 year-old children by immigrant status..."
              action={{
                type: "internal",
                route: "https://doi.org/10.1007/s10903-018-0841-3",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Dietary Acculturation: Definition, Process, Assessment, and Implications"
              description="Over the past few decades, changes in patterns of behavior have led to major changes in health status (obesity, Type II diabetes mellitus, cardiovascular disease, cancer). This epidemiologic transition is largely the result of rapid increases in immigration to developed countries and rural-urban migration within developing countries..."
              action={{
                type: "internal",
                route: "https://www.researchgate.net/publication/263437450_Dietary_Acculturation_Definition_Process_Assessment_and_Implications",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Effects of migration on nutrition"
              description="How does migration affect the nutrition of individuals and families? This article explores the impact of migration on nutrition and health outcomes..."
              action={{
                type: "internal",
                route: "/landing#news-articles",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default News;
